<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container">
    <div class="system-pages-title d-flex ">
      <div class="me-2 trusted-icon">
        <TrustedAgenciesIcon/>
      </div>
      {{ $t('trusted-agencies') }}
    </div>
    <div class="system-pages-content">
      <p><strong>Търсете синия маркер</strong> - в нашата платформа може да намерите само реални обяви от предварително
        одобрени и надеждни агенции за недвижими имоти.</p>
      <p><br></p>
      <p>Разчитаме на коректността и сме ограничили свободното регистриране на агенции за имоти - <strong>единствено физическите
        лица могат да се регистрират свободно</strong>. Това означава, че в нашата платформа може да намерите само два вида обяви
        - от физически лица (без маркер) или коректни агенции (със син маркер). Имаме механизми за предотвратяването и
        минимизирането на фалшиви обяви, което приемаме като наша мисия.</p>
      <p><br></p>
      <p>На първо място държим на качеството - всяка агенция за недвижими имоти и всеки партньор на Съквартирантите е
        предварително проверен и задължен да спазва добрите практики с дългосрочен договор за партньорство. При системни
        нарушения, ние снемаме доверието си и прекратяваме работа с дадения брокер или партньор.</p>
    </div>
  </div>


</template>
